import { Component, Input } from '@angular/core';
import { ResourcesModel } from '../../../../../../server/data/models/resources.model';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-resource-card',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    NgOptimizedImage,
    MatButtonModule,
    MatIconModule,
    RouterModule,
  ],
  template: `
    <mat-card
      [class]="
        isLinkable
          ? 'resource-card border-radius-0'
          : 'resource-card border-radius-0 no-link'
      "
      (click)="handleLinkClick()"
      (keydown.enter)="handleLinkClick()"
      [attr.role]="isLinkable ? 'link' : null"
      tabindex="0"
    >
      <div class="card-content">
        <img
          [src]="resource.photoUrl"
          alt="{{ resource.photoAltText }}"
          (error)="onError($event)"
          height="150"
          width="150"
        />
        <div class="info">
          <div class="info-content">
            <mat-card-title class="text-align-center full-width">{{
              resource.name
            }}</mat-card-title>
            @if (resource.resourceDescription){
            <p class="text-align-center">
              {{ resource.resourceDescription }}
            </p>
            }
          </div>
        </div>
      </div>
    </mat-card>
  `,
  styles: [
    `
      .resource-card {
        overflow: hidden;
        cursor: pointer;
        border-radius: 4px;
      }

      .no-link {
        cursor: default;
      }

      .resource-card .card-content {
        position: relative;
        height: 275px;
        padding: 16px;
      }

      .resource-card img {
        width: 100%;
        /*height: auto;*/
        object-fit: contain;
        transition: opacity 0.3s ease, transform 0.3s ease;
        /*opacity: 0; */ /* Start with image invisible */
      }

      .resource-card .info {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(179, 27, 27, 0.93);
        padding: 16px;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        transform: translateY(65%);
        transition: transform 0.3s ease;
      }

      .resource-card .info-content {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        line-height: 1.5;
      }

      .resource-card:focus img,
      .resource-card:hover img {
        transform: scale(1.1);
      }

      .resource-card:focus .info,
      .resource-card:hover .info {
        transform: translateY(0);
      }

      mat-card-title {
        min-height: 34%;
        margin-bottom: 10px;
        align-self: flex-start;
      }
    `,
  ],
})
export class ResourceCardComponent {
  @Input({ required: true }) resource!: ResourcesModel;
  @Input() isLinkable: boolean = true;

  onError(event: any) {
    event.target.src = 'assets/images/bold_cornell_logo_simple_b31b1b.svg'; // Provide a fallback image path if needed
  }

  handleLinkClick() {
    if (!this.isLinkable) {
      return;
    }
    window.open(this.resource.resourceUrl, '_blank');
  }
}
